import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import elydorLogo from '../../images/elydor-logo.png';
import cultexp from '../../images/cultexp.png';
import tecnosia from '../../images/tecnosia.png';
import cnrs from '../../images/logo-cnrs_fondation.png';
import intell from '../../images/intel-liftoff.png';
import './AppLayout.css'; // Import your custom CSS file for styling

const AppLayout = (props) => {
  return (
    <div className="app-layout">
      {/* Header */}
      <header className="header">
        <div className="container-fluid">
          <div className="header-content">
            {/* Logo on the left */}
            {/* <div className="logo-left">
              <a href="/">
              <img className="img-fluid" src={engineImage} alt="Left Logo" />
              </a>
            </div> */}

            {/* Centered content */}
            <div className="text-center">
              <div className="header-title">
                <h1 style={{ fontSize: 58, marginRight: 20 }}>
                  <a href="/" style={{textDecoration:"none"}}>
                    <span className="blue-text">Ely</span>
                    <span className="red-text">dor</span>
                  </a>
                </h1>
                <a href="/">
                  <img className="img-fluid" width={100} src={elydorLogo} alt="Left Logo" />
                </a>
              </div>
            </div>

            {/* Logo on the right */}
            {/* <div className="logo-right">
              <img className="img-fluid" src={cultexp} alt="Right Logo" />
            </div> */}
          </div>
        </div>
      </header>

      <div className="main">{props.children}</div>

      {/* Footer */}
      <footer className="footer">
        <div className="container-fluid">
          <div className="row align-items-center">
            {/* Logos on the left */}
            <div className="col-3">
              <div className="logo-left">
                <a href="https://tecnosia.net/" target="_blank" rel="noopener noreferrer">
                  <img className="img-fluid" src={tecnosia} alt="Left Logo" />
                </a>
              </div>
            </div>
            <div className="col-3">
              <div className="logo-left">
                <a href="https://www.cnrs.fr/fr" target="_blank" rel="noopener noreferrer">
                  <img className="img-fluid" style={{maxHeight:60}} src={cnrs} alt="Left Logo" />
                </a>
              </div>
            </div>
            <div className="col-3 d-flex justify-content-center align-items-center">
              <div className="logo-left">
                <a href="https://culturalexpertise.net/cultexp/" target="_blank" rel="noopener noreferrer">
                  <img className="img-fluid" src={cultexp} alt="Left Logo" />
                </a>
              </div>
            </div>

            {/* Logo on the right */}
            <div className="col-3 d-flex justify-content-end align-items-center">
              <div className="logo-left">
                <a href="https://www.intel.com/content/www/us/en/developer/tools/oneapi/liftoff.html" target="_blank" rel="noopener noreferrer">
                  <img className="img-fluid" src={intell} alt="Right Logo" style={{ width: "auto", height: "60px", objectFit: "cover" }} />
                </a>
              </div>
            </div>
          </div>

          {/* Footer text */}
          <div className="row text-center">
            <div className="col-12">
              <h6>All rights protected ©️ CULTEXP 2024</h6>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AppLayout;
