// src/components/HomePage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Container, Form, ProgressBar } from 'react-bootstrap';
import SettingsMenu from './SettingsMenu';
import entityVerification from '../images/entity-verification.png';
import downloadIcon from '../images/downloadicon.png';
import settingsIcons from '../images/settingsicon.png';
import {Api_url} from "../constants";
import Loader from './ui/Loader';

const HomePage = () => {
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [settingCallback, setSettingCallback] = useState(null);
  const [handleFileCallback, setHandleFileCallback] = useState(false);
  const [downloadProcessedFileStatus, setdownloadProcessedFileStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [processedResult, setProcessedResult] = useState();
  const [settingsData, setSettingsData] = useState({});
  const [settingsSelectedData, setSettingsSelectedData] = useState({});
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (progress === 100 && loading === false) {
  //     setProgress(0);
  //   }
  // }, [progress, loading]);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setHandleFileCallback(true);
  };
  const handleCloseSettings = () => {
    setIsSettingsOpen(false);
  };
  const handleOpenSettings = () => {
    setIsSettingsOpen(true);
  };
  


  const handleAnonymize = async (upload = true, persons = null, dates = null, locations = null, personal_identifiers = null, inputFIlename = null, method = null) => {
    // Check if a file is selected
    if (!file && upload === true) {
      alert('Please select a file to anonymize.');
      return;
    }

    setLoading(true);
    let intervalTime = 0;
    let progressInterval;
    if(method==="entity-verification"){
      setLoader(true);
    }
    function intervalCallback() {
      setProgress((prevProgress) => {
        if (prevProgress > 0 && prevProgress <= 30) {
          intervalTime = 1000;
          clearInterval(progressInterval);
          startInterval();
        } else if (prevProgress > 30 && prevProgress <= 60) {
          intervalTime = 3000;
          clearInterval(progressInterval);
          startInterval();
        } else if (prevProgress > 60 && prevProgress <= 90) {
          intervalTime = 5000;
          clearInterval(progressInterval);
          startInterval();
        } else if (prevProgress > 90 && prevProgress <= 99) {
          intervalTime = 8000;
          clearInterval(progressInterval);
          startInterval();
        }
        return prevProgress === 100 ? 0 : prevProgress < 99 ? prevProgress + 1 : prevProgress;
      });
    }

    function startInterval() {
      progressInterval = setInterval(intervalCallback, intervalTime);
    }

    startInterval();

    
    try {
      // Make API call to the Python server for file upload

      let response;
      if(upload === true) {   
        const formData = new FormData();
        formData.append('file', file);
        setSettingsSelectedData({});
        response = await fetch(`${Api_url}/upload`, {
          method: 'POST',
          body: formData,
        });
      } else {
        const selectedSettings = {
          persons,
          locations,
          dates,
          personal_identifiers,
          input_filename:inputFIlename
        };
        setSettingsSelectedData({persons,locations,dates,personal_identifiers});
        response = await fetch(`${Api_url}/process-selection`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedSettings),
        });
      }

      if (!response.ok) {
        throw new Error('An error occurred during file upload.');
      }

      const result = await response.json();
      var wordsChangedLength = 0;
      const sortedSpans = result.spans.sort((a, b) => a.start - b.start);
      // Use a Set to keep track of unique objects
      const uniqueSpans = new Set();

      // Filter out duplicates based on start and end values
      const filteredSpans = sortedSpans.filter((span) => {
        const key = `${span.start}-${span.end}`;
        if (!uniqueSpans.has(key)) {
          uniqueSpans.add(key);
          return true;
        }
        return false;
      });
      const completeSpansList = filteredSpans.map((span, index) => {
        const startAnonyIndex = index === 0 ? span.start : (span.start - wordsChangedLength) + (index * 3);
        const endAnonyIndex = startAnonyIndex + 3;
        wordsChangedLength = wordsChangedLength + span.word.length;
        return {
          ...span,
          startAnonyIndex,
          endAnonyIndex,
        };
      });
      setLoading(false);
      setShow(true);
      setProcessedResult({...result, completeSpansList});
      if(upload === true){
        setSettingsData({persons:result.persons, dates:result.dates, locations:result.locations, personal_identifiers:result.personal_identifiers})
      } else {
        setdownloadProcessedFileStatus(true);
        setSettingCallback(method);
      }
      clearInterval(progressInterval);
      setProgress((prevProgress)=>(prevProgress !== 100 && 100));
      // Show the download and entity verification buttons
      
    } catch (error) {
      console.error('File upload failed:', error.message);
      alert('File upload failed:'+ error.message);
      // Handle error accordingly
      clearInterval(progressInterval);
      setProgress((prevProgress)=>(prevProgress !== 100 && 100));
      setLoading(false);
      setLoader(false);
    }
  };

  const downloadProcessedFile = async () => {
    try {
      const selectedSettings = {
        persons:settingsSelectedData.persons || settingsData.persons,
        locations:settingsSelectedData.locations || settingsData.locations,
        dates:settingsSelectedData.dates || settingsData.dates,
        personal_identifiers:settingsSelectedData.personal_identifiers || settingsData.personal_identifiers,
        input_filename:processedResult.input_filename
      };

       const responseFirst = await fetch(`${Api_url}/process-selection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...selectedSettings}),
      });
      if (!responseFirst.ok) {
        throw new Error('Failed to download processed file.');
      }
      const result = await responseFirst.json();
      const response = await fetch(`${Api_url}${result.output_filename}`);
      
      if (!response.ok) {
        throw new Error('Failed to download processed file.');
      }
  
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = result.output_filename.split('/')[result.output_filename.split('/').length-1];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed:', error.message);
      alert('Download failed:'+ error.message);
      // Handle error accordingly
    }
  };

  const handleEntityVerification = () => {
    navigate('/entity-verification', {
      state: {
        input_filename: processedResult.input_filename,
        anonymizedContent: processedResult.processed_content,
        originalContent: processedResult.original_content,
        spans: processedResult.completeSpansList,
        persons:settingsSelectedData.persons || settingsData.persons,
        locations:settingsSelectedData.locations || settingsData.locations,
        dates:settingsSelectedData.dates || settingsData.dates,
        personal_identifiers:settingsSelectedData.personal_identifiers || settingsData.personal_identifiers,
      },
    })
  }

  useEffect(() => {
    if(downloadProcessedFileStatus && settingCallback){
      if(settingCallback === "download"){
        downloadProcessedFile();
      } else if(settingCallback === "entity-verification"){
        setLoader(false);
        handleEntityVerification();
      }
      setdownloadProcessedFileStatus(false);
      setSettingCallback(null);
    }
    if(handleFileCallback){
      setHandleFileCallback(false);
      handleAnonymize();
    }
  }, [downloadProcessedFileStatus, handleFileCallback])

  return (
      <Container className="mt-5 text-center" style={{ maxWidth: "50%" }}>
        <Form style={{ display: "flex", justifyContent: "center", alignItems: "center",}}>
          <Form.Group controlId="fileInput" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "50%" }}>
            <label htmlFor="customFileInput" style={{ border: "2px solid #888888",fontSize:24, color:'#888888', padding: "8px 12px", width:"100%", cursor: "pointer", borderRadius: "4px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"  }}>
              { file?.name || "Upload File"}
            </label>
            <input
              type="file"
              id="customFileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Form.Group>
          {/* <Button
            onClick={() => handleAnonymize()}
            style={{
              maxWidth: '20%',
              backgroundColor: '#0070c0',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
          >
            Process
          </Button> */}
        </Form>
        {progress > 0 && (
        <div style={{ maxWidth: "50%", margin: "auto", height: "40px", backgroundColor: "#0070c0", borderRadius: "10px", overflow: "hidden", marginTop: "10px", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: "0",
              left: `${progress*.95}%`, // Adjust the position of the circle
              width: "40px",
              height: "40px",
              backgroundColor: "#888888",
              borderRadius: "20%",
              transform: "translateX(-50%)", // Center the circle
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "left 0.3s ease"
            }}
          >
            <span style={{ color: "#333", fontSize: "12px" }}>{progress}%</span>
          </div>
        </div>
      )}
        {!loading && show && (
          <div className="mt-3" style={{ width: "50%", margin: "auto", display:"flex", justifyContent:"space-around", alignItems:"center"}}>
            <div onClick={handleEntityVerification}
              style={{ cursor: 'pointer' }}
              title="Entity Verification"
            >
              <img src={entityVerification} style={{ width: '32px' }} alt="entity-verification"/>
            </div>
            <div 
              onClick={() => handleOpenSettings()}
              style={{ cursor: 'pointer' }}
              title="Settings"
              >
              <img src={settingsIcons} style={{ width: '32px' }} alt="settingsIcons"/>
            </div>
            <div 
              onClick={() => downloadProcessedFile(processedResult.output_filename)}
              style={{ cursor: 'pointer' }}
              title="Download Processed File"
              >
              <img src={downloadIcon} style={{ width: '32px' }} alt="settingsIcons"/>
            </div>
          </div>
        )}
        {isSettingsOpen && (
        <SettingsMenu 
          onClose={handleCloseSettings}
          onHandleEntityVerification={handleEntityVerification}
          inputFIlename={processedResult.input_filename}
          persons={settingsData.persons}
          locations={settingsData.locations}
          dates={settingsData.dates}
          personal_identifiers={settingsData.personal_identifiers}
          settingsSelectedData={settingsSelectedData}
          handleAnonymize={handleAnonymize}
        />
      )}
      {loader && (<Loader/>)}
    </Container>
  );
};

export default HomePage;
