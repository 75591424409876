import bcrypt from 'bcryptjs';
import CryptoJS from 'crypto-js';

const users = [
  { username: 'admin', passwordHash: '$2a$10$InCZ19ICGzCHEFa1SHNSW.E6cWgvPjyLHYkfdrcW0Wi8nFkhVB5WC' },
];

const secretKey = process.env.REACT_APP_SECRET_KEY;

let authenticatedUser = null;

export const getAuthenticatedUser = () => {
  const storedEncryptedPassword = localStorage.getItem('encryptedPassword');
  const tokenExpiration = localStorage.getItem('tokenExpiration');

  if (storedEncryptedPassword && tokenExpiration) {
    const expirationTime = parseInt(tokenExpiration, 10);

    // Check if the token has expired
    if (Date.now() < expirationTime) {
      // Decrypt the password
      const decryptedPassword = CryptoJS.AES.decrypt(storedEncryptedPassword, secretKey).toString(CryptoJS.enc.Utf8);
      return { username: 'admin', password: decryptedPassword };
    } else {
      // Token has expired, perform logout
      logoutUser();
    }
  }

  return null;
};

export const authenticateUser = async (username, password) => {
  const user = users.find((u) => u.username === username);

  if (user) {
    const passwordMatch = await bcrypt.compare(password, user.passwordHash);
    if (passwordMatch) {
      authenticatedUser = user;

      // Encrypt the password before saving to localStorage
      const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();

      localStorage.setItem('encryptedPassword', encryptedPassword);
      
      // Set an expiration time (e.g., 1 hour)
      const expirationTime = Date.now() + 60 * 60 * 1000;
      localStorage.setItem('tokenExpiration', expirationTime.toString());

      return true;
    }
  }

  return false;
};

export const logoutUser = () => {
  authenticatedUser = null;

  // Remove user data and expiration time from localStorage
  localStorage.removeItem('encryptedPassword');
  localStorage.removeItem('tokenExpiration');
};
