// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import EntityVerificationPage from './components/EntityVerificationPage';
import Login from './components/Login';
import AppLayout from './components/layout/AppLayout';
import '@fortawesome/fontawesome-free/css/all.css';
import { getAuthenticatedUser } from './utils/userService';

const PrivateRoute = ({ element }) => {
  const isAuthenticated = getAuthenticatedUser() !== null;

  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

function App() {
  return (
    <Router>
      <AppLayout>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute element={<HomePage/>} />} />
          <Route path="/entity-verification" element={<PrivateRoute element={<EntityVerificationPage/>} />} />
        </Routes>
      </AppLayout>
    </Router>
  );
}

export default App;
