import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-plain_text';
import 'ace-builds/src-noconflict/theme-github';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import SettingsMenu from './SettingsMenu';
import downloadIcon from '../images/downloadicon.png';
import {Api_url} from "../constants";


const EntityVerificationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { originalContent, anonymizedContent, spans, persons, personal_identifiers, dates, locations, input_filename } = location?.state || {};
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [updatedSpans, setUpdatedSpans] = useState(spans);
  const [anonymizedUpdatedContent, setanonymizedUpdatedContent] = useState(anonymizedContent);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [dummyData, setDummyData] = useState(['Setting 1', 'Setting 2', 'Setting 3']);

  const handleOpenSettings = () => {
    setIsSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setIsSettingsOpen(false);
  };

  const handleNext = () => {
    setHighlightedIndex((prevIndex) => Math.min(prevIndex + 1, updatedSpans.length - 1));
  };

  const handlePrev = () => {
    setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleAccept = (last=false) => {
    if(!last){
      setHighlightedIndex((prevIndex) => Math.min(prevIndex + 1, updatedSpans.length - 1));
    }
  };

  const handleReject = () => {
    const currentSpan = updatedSpans[highlightedIndex];
   
    // Replace content in anonymizedContent between startAnonyIndex and endAnonyIndex with span.word
    const startIdx = currentSpan.startAnonyIndex;
    const endIdx = currentSpan.endAnonyIndex;
    const updatedAnonymizedContent =
      anonymizedUpdatedContent.substring(0, startIdx) + currentSpan.word + anonymizedUpdatedContent.substring(endIdx);

      var clonedUpdatedSpans = updatedSpans.map((span, index)=>{
        if(highlightedIndex === index){
          return {
            ...span,
            replacedWord:span.word
          }
        }
        return span;
      })
      var wordsChangedLength = 0;
      var replacedWordsChangedLength = 0;
      var replacedWordsCount = 0;
      const completeSpansList = clonedUpdatedSpans.map((span, index) => {
        const startAnonyIndex = index === 0 ? span.start : (span.start - wordsChangedLength) + ((index - replacedWordsCount) * 3) + replacedWordsChangedLength;
        const endAnonyIndex = span.replacedWord ? startAnonyIndex +  span.replacedWord.length : startAnonyIndex +  3;
        wordsChangedLength = wordsChangedLength + span.word.length;
        if(span.replacedWord){
          console.log(startAnonyIndex,endAnonyIndex);
          replacedWordsChangedLength = replacedWordsChangedLength + span.replacedWord.length;
          replacedWordsCount++;
        }
        return {
          ...span,
          startAnonyIndex,
          endAnonyIndex,
        };
      });
    // Update spans array with the updated startAnonyIndex and endAnonyIndex for all items
    const updatedSpansArray = updatedSpans.map((span) => {
      if (span.startAnonyIndex >= startIdx && span.endAnonyIndex <= endIdx) {
        return {
          ...span,
          startAnonyIndex: startIdx,
          endAnonyIndex: startIdx + 3,
        };
      }
      return span;
    });

    // Set the updated anonymizedContent and spans
    // This might need to be handled according to your state management setup
    // For now, let's assume a function setUpdatedContent that you need to implement
    setUpdatedSpans(completeSpansList);
    setanonymizedUpdatedContent(updatedAnonymizedContent);
  };

  const handleModify = () => {
    const currentSpan = updatedSpans[highlightedIndex];
  
    // Assuming you have a state to store the modified word, e.g., modifyWord
    const modifiedWord = prompt('Enter the modified word:');
    if (modifiedWord === null || modifiedWord==="") {
      // User clicked Cancel
      return;
    }
  
    // Replace content in anonymizedContent between startAnonyIndex and endAnonyIndex with modifiedWord
    const startIdx = currentSpan.startAnonyIndex;
    const endIdx = currentSpan.endAnonyIndex;
    const updatedAnonymizedContent =
      anonymizedUpdatedContent.substring(0, startIdx) + modifiedWord + anonymizedUpdatedContent.substring(endIdx);
  
      var clonedUpdatedSpans = updatedSpans.map((span, index)=>{
        if(highlightedIndex === index){
          return {
            ...span,
            replacedWord:modifiedWord
          }
        }
        return span;
      })
      var wordsChangedLength = 0;
      var replacedWordsChangedLength = 0;
      var replacedWordsCount = 0;
      const completeSpansList = clonedUpdatedSpans.map((span, index) => {
        const startAnonyIndex = index === 0 ? span.start : (span.start - wordsChangedLength) + ((index - replacedWordsCount) * 3) + replacedWordsChangedLength;
        const endAnonyIndex = span.replacedWord ? startAnonyIndex +  span.replacedWord.length : startAnonyIndex +  3;
        wordsChangedLength = wordsChangedLength + span.word.length;
        if(span.replacedWord){
          console.log(startAnonyIndex,endAnonyIndex);
          replacedWordsChangedLength = replacedWordsChangedLength + span.replacedWord.length;
          replacedWordsCount++;
        }
        return {
          ...span,
          startAnonyIndex,
          endAnonyIndex,
        };
      });
  
    // Set the updated anonymizedContent and spans
    setUpdatedSpans(completeSpansList);
    setanonymizedUpdatedContent(updatedAnonymizedContent);
  };
  

  const editorRef = useRef(null);
  const editorAnonyRef = useRef(null);

  const handleEditorLoad = (editor) => {
    // Set up the editorRef once the Ace Editor is loaded
    editorRef.current = editor;
    setEditorLoaded(true);
  };

  const handleEditorAnonyLoad = (editor) => {
    // Set up the editorRef once the Ace Editor is loaded
    editorAnonyRef.current = editor;
    setEditorLoaded(true);
  };

  useEffect(() => {
    // Highlight the current span in the Ace Editor when the highlightedIndex changes
    if (!originalContent || !anonymizedContent) {
      navigate('/');
      return;
    }
    const currentSpan = updatedSpans[highlightedIndex];
    const editorCurrent = (editorRef.current.session && editorRef.current ) || editorRef.current.editor;
    const editorAnonyCurrent = (editorAnonyRef.current.session && editorAnonyRef.current ) || editorAnonyRef.current.editor;

    if (editorLoaded && editorCurrent && editorAnonyCurrent && currentSpan) {
      const startPos = editorCurrent.session.doc.indexToPosition(currentSpan.start);
      const endPos = editorCurrent.session.doc.indexToPosition(currentSpan.end);
      const selection = {
        start: { row: startPos.row, column: startPos.column },
        end: { row: endPos.row, column: endPos.column },
      };
      const startAnonyPos = editorAnonyCurrent.session.doc.indexToPosition(currentSpan.startAnonyIndex);
      const endAnonyPos = editorAnonyCurrent.session.doc.indexToPosition(currentSpan.endAnonyIndex);
      const selectionAnony = {
        start: { row: startAnonyPos.row, column: startAnonyPos.column },
        end: { row: endAnonyPos.row, column: endAnonyPos.column },
      };

      // Set selection range in original content editor
      editorCurrent.selection.setSelectionRange(selection);

      // Set selection range in anonymized content editor
      editorAnonyCurrent.selection.setSelectionRange(selectionAnony);

      // Scroll the selected text into view in the original content editor
      editorCurrent.renderer.scrollCursorIntoView({ row: startPos.row, column: startPos.column }, 0.3, true);
      editorCurrent.scrollToLine(startPos.row, true, true);
      
      editorAnonyCurrent.renderer.scrollCursorIntoView({ row: startAnonyPos.row, column: startAnonyPos.column }, 0.3, true);
      editorAnonyCurrent.scrollToLine(startAnonyPos.row, true, true);

    }
  }, [highlightedIndex, updatedSpans, editorLoaded, originalContent, anonymizedContent]);

  const handleDownload = async () => {
    let modified_entities = [];
    updatedSpans.forEach(span=>{
      if(span.word && span.replacedWord){
        let spanFound = false;
        modified_entities.forEach((spanedEntity, index)=>{
          if(spanedEntity[0]===span.word && spanedEntity[1]===span.replacedWord){
            modified_entities[index][0] = span.word;
            modified_entities[index][1] = span.replacedWord;
            spanFound = true;
          }
        });
        if (!spanFound) {
          modified_entities.push([span.word, span.replacedWord]);
        }
      }
    });
    try {
      const responseFirst = await fetch(`${Api_url}/entity-verification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          persons: persons,
          personal_identifiers: personal_identifiers,
          locations: locations,
          dates: dates,
          modified_entities: modified_entities,
          input_filename: input_filename,
        }),
      });

      if (responseFirst.ok) {
        const responseData = await responseFirst.json();
        const outputFilename = responseData.output_filename;
        console.log('API call successful. Output filename:', outputFilename);
        const response = await fetch(`${Api_url}${outputFilename}`);

      
        if (!response.ok) {
          throw new Error('Failed to download processed file.');
        }
    
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = outputFilename.split('/')[outputFilename.split('/').length-1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Perform any additional actions based on the API response
      } else {
        console.error('API call failed:', responseFirst.statusText);
      alert('API call failed:'+ responseFirst.statusText);

      }
    } catch (error) {
      console.error('Error during API call:', error.message);
      alert('Error during API call:'+ error.message);
    }
  };
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6 offset-md-3 d-flex justify-content-center align-items-center">
          <button className="btn btn-link mr-2" onClick={handlePrev} disabled={highlightedIndex === 0}>
            <i className="fas fa-arrow-left fa-2x"></i> {/* Left arrow icon */}
          </button>
          <button className="btn btn-link" onClick={handleNext} disabled={highlightedIndex === spans?.length - 1}>
            <i className="fas fa-arrow-right fa-2x"></i> {/* Right arrow icon */}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <AceEditor
            mode="plain_text"
            theme="github"
            value={originalContent}
            editorProps={{ $blockScrolling: true }}
            readOnly
            style={{ width: '100%' }}
            ref={editorRef}
            onLoad={handleEditorLoad}
          />
        </div>
        <div className="col-md-6">
          <AceEditor
            mode="plain_text"
            theme="github"
            value={anonymizedUpdatedContent}
            editorProps={{ $blockScrolling: true }}
            readOnly
            style={{ width: '100%' }}
            ref={editorAnonyRef}
            onLoad={handleEditorAnonyLoad}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div style={{textAlign:"center", position:"relative"}}>
          <div 
            onClick={handleDownload}
            style={{ cursor: 'pointer',right:"0px", position:"absolute" }}
            title="Apply and Download"
          >
            <img src={downloadIcon} style={{ width: '32px' }} alt="settingsIcons"/>
          </div>
          <button title="Accept" className="btn mr-2 ml-4" onClick={()=>handleAccept(highlightedIndex === spans?.length - 1)}>
            <i className="fas fa-check fa-2x"></i> {/* Accept (Tick) icon */}
          </button>
          <button title="Reject" className="btn mr-2" onClick={handleReject}>
            <i className="fas fa-times fa-2x"></i> {/* Reject (Cross) icon */}
          </button>
          <button title="Modify" className="btn mr-2" onClick={handleModify}>
            <i className="fas fa-edit fa-2x"></i> {/* Modify (Edit) icon */}
          </button>
        </div>
      </div>
      {isSettingsOpen && (
        <SettingsMenu 
          onClose={handleCloseSettings} 
          dummyData={dummyData} 
          persons={persons}
          locations={locations}
          dates={dates}
        />
      )}
    </div>
  );
};

export default EntityVerificationPage;
