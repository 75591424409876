import React, { useState } from 'react';
import './settings.css';
import { Button } from 'react-bootstrap';
import entityVerification from '../images/entity-verification.png';
import downloadIcon from '../images/downloadicon.png';



const SettingsMenu = ({ onClose, persons, locations, dates, personal_identifiers, handleAnonymize, inputFIlename, settingsSelectedData, onHandleEntityVerification }) => {
  // State to manage the selected checkboxes
  const [selectedPersons, setSelectedPersons] = useState(settingsSelectedData.persons ? settingsSelectedData.persons.map(person => person) : persons.map(person => person));
  const [selectedLocations, setSelectedLocations] = useState(settingsSelectedData.locations ? settingsSelectedData.locations.map(location => location) : locations.map(location => location));
  const [selectedDates, setSelectedDates] = useState(settingsSelectedData.dates ? settingsSelectedData.dates.map(date => date) : dates.map(date => date));
  const [selectedPersonal_identifiers, setSelectedPersonal_identifiers] = useState(settingsSelectedData.personal_identifiers ? settingsSelectedData.personal_identifiers.map(pi => pi) : personal_identifiers.map(pi => pi));

  // Function to handle checkbox changes
  const handleCheckboxChange = (value, type) => {
    switch (type) {
      case 'persons':
        setSelectedPersons((prevSelected) => {
          if (prevSelected.includes(value)) {
            // Deselecting the checkbox
            return prevSelected.filter((person) => person !== value);
          } else {
            // Selecting the checkbox
            return [...prevSelected, value];
          }
        });
        break;
      case 'locations':
        setSelectedLocations((prevSelected) => {
          if (prevSelected.includes(value)) {
            return prevSelected.filter((location) => location !== value);
          } else {
            return [...prevSelected, value];
          }
        });
        break;
      case 'dates':
        setSelectedDates((prevSelected) => {
          if (prevSelected.includes(value)) {
            return prevSelected.filter((date) => date !== value);
          } else {
            return [...prevSelected, value];
          }
        });
        break;
      case 'personal_identifiers':
        setSelectedPersonal_identifiers((prevSelected) => {
          if (prevSelected.includes(value)) {
            return prevSelected.filter((pi) => pi !== value);
          } else {
            return [...prevSelected, value];
          }
        });
        break;
      default:
        break;
    }
  };

  
  // Function to handle selecting/deselecting all checkboxes for a category
  const handleSelectAll = (type) => {
    switch (type) {
      case 'persons':
        setSelectedPersons(persons.map(person => person));
        break;
      case 'locations':
        setSelectedLocations(locations.map(location => location));
        break;
      case 'dates':
        setSelectedDates(dates.map(date => date));
        break;
      case 'personal_identifiers':
        setSelectedPersonal_identifiers(personal_identifiers.map(pi => pi));
        break;
      default:
        break;
    }
  };

  // Function to handle deselecting all checkboxes for a category
  const handleDeselectAll = (type) => {
    switch (type) {
      case 'persons':
        setSelectedPersons([]);
        break;
      case 'locations':
        setSelectedLocations([]);
        break;
      case 'dates':
        setSelectedDates([]);
        break;
      case 'personal_identifiers':
        setSelectedPersonal_identifiers([]);
        break;
      default:
        break;
    }
  };

  const onApply = (method) => {
    if(method==="download"){
      onClose();
    }
    handleAnonymize(false, selectedPersons, selectedDates, selectedLocations, selectedPersonal_identifiers, inputFIlename, method);
  }

  return (
    <div className="settings-overlay">
      <div className="settings-menu">
        <h3>Settings</h3>
        <div className="settings-content">
          <div>
              <h4>Persons {" "}
              <span>
                <input
                    type="checkbox"
                    checked={selectedPersons.length === persons.length}
                    onChange={() => selectedPersons.length === persons.length ? handleDeselectAll('persons') : handleSelectAll('persons')}
                    style={{cursor:"pointer"}}
                    title="Select all"
                />
              </span>
              </h4>
            <ul className="entity-list">
              {persons.map((person, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="checkbox"
                      value={person}
                      checked={selectedPersons.includes(person)}
                      onChange={() => handleCheckboxChange(person, 'persons')}
                    />
                    {person}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div>
              <h4>Locations {" "}
                <span>
                  <input
                      type="checkbox"
                      checked={selectedLocations.length === locations.length}
                      onChange={() => selectedLocations.length === locations.length ? handleDeselectAll('locations') : handleSelectAll('locations')}
                      style={{cursor:"pointer"}}
                      title="Select all"
                  />
                </span>
              </h4>
            <ul className="entity-list">
              {locations.map((location, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="checkbox"
                      value={location}
                      checked={selectedLocations.includes(location)}
                      onChange={() => handleCheckboxChange(location, 'locations')}
                    />
                    {location}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div>
              <h4>Dates {" "}
                <span>
                  <input
                      type="checkbox"
                      checked={selectedDates.length === dates.length}
                      onChange={() => selectedDates.length === dates.length ? handleDeselectAll('dates') : handleSelectAll('dates')}
                      style={{cursor:"pointer"}}
                      title="Select all"
                  />
                </span>
              </h4>
            <ul className="entity-list">
              {dates.map((date, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="checkbox"
                      value={date}
                      checked={selectedDates.includes(date)}
                      onChange={() => handleCheckboxChange(date, 'dates')}
                    />
                    {date}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div>
              <h4>Personal Identifiers {" "}
                <span>
                  <input
                      type="checkbox"
                      checked={selectedPersonal_identifiers.length === personal_identifiers.length}
                      onChange={() => selectedPersonal_identifiers.length === personal_identifiers.length ? handleDeselectAll('personal_identifiers') : handleSelectAll('personal_identifiers')}
                      style={{cursor:"pointer"}}
                      title="Select all"
                  />
                </span>
              </h4>
            <ul className="entity-list">
              {personal_identifiers.map((pi, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="checkbox"
                      value={pi}
                      checked={selectedPersonal_identifiers.includes(pi)}
                      onChange={() => handleCheckboxChange(pi, 'personal_identifiers')}
                    />
                    {pi}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div style={{display:"flex", justifyContent:"space-around"}}>
            <div onClick={()=>onApply("entity-verification")}
              style={{ cursor: 'pointer' }}
              title="Entity Verification"
            >
              <img src={entityVerification} style={{ width: '32px' }} alt="entity-verification"/>
            </div>
          
            <div 
              onClick={()=>onApply("download")}
              style={{ cursor: 'pointer' }}
              title="Apply and Download"
              >
              <img src={downloadIcon} style={{ width: '32px' }} alt="settingsIcons"/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsMenu;
